$(document).ready(function() {

    let $button = $("#homePageSliderSearch_submitButton");
    let $contactButton = $("#contactMeButton");
    let courseFinderURLRedirect = $button.data("href");
    let contactURLRedirect = $contactButton.data("href");
    var url = window.seminarsListAjaxUrl;
    url = url.replace('http', 'https');
    let $homePageSearch = $(".homePageSliderSearch_dropdown");
    let courseFinderURL = $("#courseFinderDropdowns-CF").data('href');
    let $dropdownSelect = $(".dropdownSelect");
    let $searchField = $("#search-field-CF");
    let $sortDrownElements = $(".dropdown-menu-sort-date-CF li");
    let $deleteAllFilters = $(".deleteAllFilters-CF");
    let $resultCounter = $("#resultCounter-CF");
    let $sortedTitle = $("#sortedTitle");
    let $speakersSeeAllButton = $("#speakersSeeAll");
    let reachedMax = false;
    let page = 2;
    let inAjaxCall = false;

    if($("#courseFinderDropdowns-CF").length) {
        var filters = [];
        var favourites = [];
        var DropdownsEnum = Object.freeze({
            "CareerLevelDropdown": "1",
            "TopicsDropdown": "2",
            "FormatDropdown": "3",
            "TypeOfEducationDropdown": "4",
            "LanguageDropdown": "5",
            "DateDropdown": "6"
        });

        function isMobileDevice() {
            if ($(window).width() <= 768) {
                return true;
            } else {
                return false;
            }
        }

        // Favourite star
        $("#see-all-favourite-button-CF").click(function (clickedEl) {
            if (favourites.length == 0) {
                return;
            }
            const clickedEL = $(this);
            var smallHeight = "50px";
            var bigHeight = "120px";
            if (isMobileDevice()) {
                smallHeight = "156px";
                bigHeight = "220px";
            }

            $("#courseFinderCourses").removeClass("favourites-count-2");

            if (clickedEL.hasClass("clickedElFavourites")) {
                // if the element is selected
                clickedEL.removeClass("clickedElFavourites");
                clickedEL.find(".full-star").css("visibility", "hidden");
                clickedEL.find(".empty-star").css("visibility", "visible");
                // hide the contact me button
                $(".courseFinderSearchBar-CF").css("height", smallHeight);
                $("#contactMeButton").css("display", "none");
                // show all courses

                updatePage(false,false);

            } else {
                // if the element is not selected
                clickedEL.addClass("clickedElFavourites");
                clickedEL.find(".full-star").css("visibility", "visible");
                clickedEL.find(".empty-star").css("visibility", "hidden");
                // show the contact me button
                $(".courseFinderSearchBar-CF").css("height", bigHeight);
                $("#contactMeButton").css("display", "block");

                updatePage(false,true);

                if (favourites.length == 2) {
                    $("#courseFinderCourses").addClass("favourites-count-2");
                }
            }
        });

        function unmarkFavouriteElement(clickedEL) {
            clickedEL.removeClass("clickedEl1");
            clickedEL.find(".fullStar").css("visibility", "hidden");
            clickedEL.find(".emptyStar").css("visibility", "visible");
        }

        // Favourite star
        $(document).on("click", ".favouriteCourseButton", function () {
            const clickedEL = $(this);
            const singleCourseItemId = clickedEL.parent().parent().parent().attr("data-courseid");

            saveFavoriteSeminars(clickedEL.data("value"));

            if (clickedEL.hasClass("clickedEl1")) {
                // if the element is selected
                var index = favourites.indexOf(singleCourseItemId);
                if (index > -1) {
                    favourites.splice(index, 1);
                }
                unmarkFavouriteElement(clickedEL);
            } else {
                // if the element is not selected
                favourites.push(singleCourseItemId);
                clickedEL.addClass("clickedEl1");
                clickedEL.find(".fullStar").css("visibility", "visible");
                clickedEL.find(".emptyStar").css("visibility", "hidden");
            }

            if (favourites.length > 0) {
                $(".see-favourites-CF").css("display", "inline-block");
            } else {
                $(".see-favourites-CF").css("display", "none");
            }
        });

        // select filter from dropdown menu
        $(".dropdown-topics-menu-CF li, .dropdown-menu-CF li").click(function () {
            const clickedEL = $(this);
            const clickedElementValue = clickedEL.find("p").text();
            const dataDropdownType = clickedEL.find("p").attr("data-dropdowntype");

            if (clickedEL.hasClass("clickedEl")) {
                // if the element is selected
                clickedEL.find("p").attr("data-value", "");
                unmarkDropdownElement(clickedEL, clickedElementValue);
            } else {
                // if the element is not selected
                clickedEL.addClass("clickedEl");
                clickedEL.find("i").css("visibility", "visible");
                clickedEL.find(".dropdown-title").css("color", "#79a342");
                clickedEL.attr("data-value", clickedElementValue);
                filters.push(clickedElementValue);
                if (filters.length > 0) {
                    showFilters();
                }

                $("#addedFilters-CF").append('<div data-valuefilter="' + clickedElementValue + '" class="single-filter-item-CF ' + getProperClassForDropdownBorder(dataDropdownType) + '"><p>' + clickedElementValue + '</p><div class="close-filter-button-CF"><i class="fas fa-times"></i></div></div>');
                deleteSingleFilter(clickedElementValue);
            }
        });

        function unmarkDropdownElement(clickedEL, clickedELValue) {
            clickedEL.removeClass("clickedEl");
            clickedEL.find("i").css("visibility", "hidden");
            clickedEL.find(".dropdown-title").css("color", "#666");
            var index = filters.indexOf(clickedELValue);

            if (index > -1) {
                filters.splice(index, 1);
            }

            // find data value and remove the item
            $('[data-valuefilter="' + clickedELValue + '"]').remove();

            // if filter array is empty hide filters in use as well
            showFilters();
        }

        function getProperClassForDropdownBorder(typeDropdown) {
            var classDropdown = "";
            switch (typeDropdown) {
                case DropdownsEnum.CareerLevelDropdown: {
                    classDropdown = "single-filter-item-career-level-CF";
                    break;
                }
                case DropdownsEnum.TopicsDropdown: {
                    classDropdown = "single-filter-item-topic-CF";
                    break;
                }

                case DropdownsEnum.FormatDropdown: {
                    classDropdown = "single-filter-item-format-CF";
                    break;
                }

                case DropdownsEnum.TypeOfEducationDropdown: {
                    classDropdown = "single-filter-item-type-of-education-CF";
                    break;
                }

                case DropdownsEnum.LanguageDropdown: {
                    classDropdown = "single-filter-item-language-CF";
                    break;
                }

                case DropdownsEnum.DateDropdown: {
                    classDropdown = "single-filter-item-date-CF";
                    break;
                }
                default: {
                    classDropdown = "";
                }
            }

            return classDropdown;
        }

        deleteAllFilter();
        // Delete All filters
        function deleteAllFilter() {
            $(".deleteAllFilters-CF").click(function () {
                // remove all filters from filters array
                filters = [];
                $("#search-field-CF").val("");
                $("#addedFilters-CF").find(".single-filter-item-CF").remove();
                showFilters();
                $('[data-value]').each(function () {
                    if ($(this).hasClass("clickedEl")) {
                        $(this).removeClass("clickedEl");
                        $(this).find("i").css("visibility", "hidden");
                        $(this).find(".dropdown-title").css("color", "#666");
                    }
                });
                $("#courseFinderDropdowns-CF .filtersDisabled").removeClass("filtersDisabled");
                window.history.pushState({}, '', courseFinderURL);
                debouncedAjaxReloadCourses(url);
            });
        }

        function deleteSingleFilter(clickedElementValue) {
            // Delete single filter

            $(".close-filter-button-CF").click(function (clickedEl) {
                const clickedElementValue = $(this).parent().find("p").text();
                if ($(this).parent().hasClass("search-bubble")) {
                    $searchField.val("");
                    // updatePage(false);
                }

                $(this).parent().remove();

                const index = filters.indexOf(clickedElementValue);

                if (index > -1) {
                    filters.splice(index, 1);
                }

                showFilters();

                const menuElement = $('[data-value="' + clickedElementValue + '"]');

                menuElement.removeClass("clickedEl");
                menuElement.find("i").css("visibility", "hidden");
                menuElement.find(".dropdown-title").css("color", "#666");
                // updatePage(false);
            });
        }


        //////////////////////////////////////
        // Show Less / Show More
        function showLessMore() {
            $('.showLessFilters-CF').click(function () {
                let language = $("#language").data("language");
                if ($(this).attr("data-isless") !== "true") {
                    $("#addedFilters-CF").css("height", "auto");
                    if (language === "en") {
                        $(this).text("Show Less");
                    } else {
                        $(this).text("Zeige weniger");
                    }
                    $(this).attr("data-isless", "true");
                } else {
                    $("#addedFilters-CF").css("height", "0")
                    if (language === "en") {
                        $(this).text("Show More");
                    } else {
                        $(this).text("Zeige mehr");
                    }

                    $(this).attr("data-isless", "false");
                }
            });
        }
        showLessMore();

        function showFilters() {
            if (filters.length > 0) {
                $("#filtersInUse-CF").css("display", "block");
                $("#addedFilters-CF").css("display", "block");
            } else {
                $("#filtersInUse-CF").css("display", "none");
                $("#addedFilters-CF").css("display", "none");
            }
        }

        /// mobile
        $("#mobileShowDropdowns").click(function () {
            if ($(this).attr("data-dropdownShow") != "true") {
                $("#courseFinderDropdowns-CF").css("display", "block");
                $("#filtersInUse-CF").css("display", "block");
                $(".filter-in-user-mobile-container").css("display", "none");
                //$(".courseFinderSearchBar-CF").css("display", "none");
                //$("#courseFinderCourses").css("display", "none");
                $(".mobile-dropdown-arrow-down").css("display", "block");

                $(this).attr("data-dropdownShow", "true");
            } else {
                $("#courseFinderDropdowns-CF").css("display", "none");
                $("#filtersInUse-CF").css("display", "block");
                $(".filter-in-user-mobile-container").css("display", "block");
                //$(".courseFinderSearchBar-CF").css("display", "block");
                //$("#courseFinderCourses").css("display", "block");
                $(".mobile-dropdown-arrow-down").css("display", "none");

                $(this).attr("data-dropdownShow", "false");
            }
        });

        $("#mobileSearchCourseFinder").click(function () {
            $("#courseFinderDropdowns-CF").css("display", "none");
            $("#filtersInUse-CF").css("display", "block");
            $(".filter-in-user-mobile-container").css("display", "block");
            $(".courseFinderSearchBar-CF").css("display", "block");
            $("#courseFinderCourses").css("display", "block");
            $(".mobile-dropdown-arrow-down").css("display", "none");

            $("#mobileShowDropdowns").attr("data-dropdownShow", "false");
        });

        /*Functions*/
        function getQueryParameters() {
            let queryString = window.location.search || '';
            let keyValPairs = [];
            let params = {};
            queryString = queryString.replace(/.*?\?/, "");
            if (queryString.length) {
                keyValPairs = queryString.split('&');
                for (let pairNum in keyValPairs) {
                    let key = keyValPairs[pairNum].split('=')[0];
                    if (!key.length) continue;
                    if (typeof params[key] === 'undefined')
                        params[key] = [];
                    params[key].push(keyValPairs[pairNum].split('=')[1]);
                }
            }
            return params;
        }

        function getDataFromParentRecursive($element) {
            let $parent = $element.parent();
            if ($parent.data("name") !== undefined) {
                return $parent.data("name");
            } else {
                return getDataFromParentRecursive($parent);
            }
        }

        function getSelectedElementsData() {
            return $(".clickedEl").map(function () {
                let $listElement = $(this).children(".dropdown-title").first();
                let $value = null;

                if ($listElement.data("dropdowntype") === 6) {
                    $value = $listElement.data("month");
                } else {
                    $value = $listElement.data("value");
                }

                let $name = getDataFromParentRecursive($(this));

                return {name: $name, value: $value};
            });
        }

        function getSelectedFavouritesData() {
            return $(".clickedEl1").map(function () {
                let $value = $(this).data("value");
                return {value: $value};
            });
        }

        function getSpecificTopic() {
            return $(".clickedEl").map(function () {
                let $value = $(this).data("specifictopic");
                return {value: $value};
            });
        }

        function ajaxReloadCourses(ajaxUrl) {
            $.ajaxq("courseFinder", {
                type: "GET",
                url: ajaxUrl,
                success: function (response) {
                    $('.loader').show();
                    let $response = $("<div>" + response + "</div>");
                    let $responseCourses = $response.find("#courseFinderCourses");
                    let $total = $responseCourses.data("total");
                    if ($responseCourses) {
                        $("#courseFinderCourses").replaceWith($responseCourses);
                        // $("#courseFinderCourses").jscroll({
                        //     padding: 900,
                        //     nextSelector: '.next-selector:last'
                        // });
                    }
                    $resultCounter.text($total);

                    let $filtersData = getSelectedElementsData();
                    if ($filtersData.length === 0) {
                        $("#courseFinderDropdowns-CF .filtersDisabled").removeClass("filtersDisabled");
                    }

                    reachedMax = false;
                    page = 2;
                    inAjaxCall = false;
                },
                complete: function () {
                    $('.loader').hide();
                }
            });
        }

        function debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        }

        var debouncedAjaxReloadCourses = debounce(ajaxReloadCourses, 300, false);

        function getNewData(ajaxUrl) {

            if (reachedMax) {
                return;
            }

            inAjaxCall = true;

            $.ajaxq('scrollQueue', {
                type: "GET",
                url: ajaxUrl,
                success: function (response) {

                    var cont = $('<div />').html(response);
                    var loadedItemsCount = cont.find('#courseFinderCourses .singleCourse').length;
                    var content2 = cont.find('#courseFinderCourses').remove('div').html();
                    $("#courseFinderCourses").append(content2);
                    updateCourseIDs();
                    if(loadedItemsCount == 0) {
                        $('.loader').hide();
                        reachedMax = true;
                    }
                    page++;
                    inAjaxCall = false;
                },
                error : function () {
                    inAjaxCall = false;
                }
            });
        }

        function saveFavoriteSeminars(seminarID) {
            var restFavUrl = $("#courseFinderCourses").data("favorites-url") + seminarID;
            $.ajax({
                type: "GET",
                url: restFavUrl,
                success: function () {

                },
                error : function () {

                }
            });
        }

        $(window).scroll(function () {

            if ($("#see-all-favourite-button-CF").hasClass("clickedElFavourites")) {

            } else {

                if (!inAjaxCall) {
                    let queryString = addAmpersandIfNecessary(buildQueryString()) + "page=" + page;
                    var toScroll = $(document).height() - $(window).height() - 300;
                    if ($(this).scrollTop() > toScroll) {
                        getNewData(url + queryString);
                    }
                }
            }
        });

        function updateCourseIDs() {
            let $index = 1;
            let $courseIDs = $('#courseFinderCourses .singleCourse');
            if ($courseIDs) {
                $.each($courseIDs, function () {
                    $(this).attr("data-courseid", $index);
                    $index++;
                });
            }
        }

        updateCourseIDs();

        function buildQueryString(isFavorites) {

            let queryString = "?";
            queryString += loadFiltersString();
            let searchValue = $searchField.val();
            if (searchValue === '') {
                searchValue = "~~";
            }
            if (isFavorites) {
                queryString += "listFavorites=true"
            } else {
                queryString = addAmpersandIfNecessary(queryString);
                queryString += "searchQuery=" + searchValue;
                queryString = addAmpersandIfNecessary(queryString);
                let sortValue = $sortedTitle.data('value');
                queryString += "sortQuery=" + sortValue;
            }


            return queryString;
        }

        function addAmpersandIfNecessary(queryString) {
            if (queryString !== "?") {
                queryString += "&"
            }
            return queryString;
        }

        function loadFiltersString() {
            let queryString = "";
            let $filtersData = getSelectedElementsData();
            for (let index = 0; index < $filtersData.length; index++) {
                if (index === 0) {
                    queryString += $filtersData[index].name + "=" + $filtersData[index].value;
                } else {
                    queryString = addAmpersandIfNecessary(queryString);
                    queryString += $filtersData[index].name + "=" + $filtersData[index].value;
                }
            }
            return queryString;
        }

        function loadFavouritesString() {
            let queryString = "";
            let $filtersData = getSelectedFavouritesData();
            for (let index = 0; index < $filtersData.length; index++) {
                if (index === 0) {
                    queryString += "goid" + "=" + $filtersData[index].value;
                } else {
                    queryString = addAmpersandIfNecessary(queryString);
                    queryString += "goid" + index + "=" + $filtersData[index].value;
                }
            }
            return queryString;
        }

        function updatePage(load, isFavorites) {
            let queryString = buildQueryString(isFavorites);
            window.history.pushState({}, '', courseFinderURL + queryString);
            if (!load) {
                debouncedAjaxReloadCourses(url + queryString);
            }
        }

        /*Functions End*/

        /*Home Page Search*/
        $homePageSearch.on("click", function () {
            let $data = getSelectedElementsData();
            let $queryURL = $button.data("query");
            $.ajax({
                type: "GET",
                url: $queryURL,
                data: $data,
                success: function (successData) {
                    $button.text(successData + " Ergebnisse ansehen");
                },
                error: function () {
                    console.log("loading error - ajax");
                }
            });
        });

        $contactButton.on("click", function () {
            let queryString = "?";
            queryString += loadFavouritesString();
            if (queryString === "?") {
                queryString = '';
            }
            window.location.href = contactURLRedirect + queryString;
        });

        $button.on("click", function () {
            let queryString = "?";
            queryString += loadFiltersString();
            if (queryString === "?") {
                queryString = '';
            }
            window.location.href = courseFinderURLRedirect + queryString;
        });
        /*End Home Page Search*/

        /*Course Finder Search*/
        $dropdownSelect.on("click", function (event, load) {
            updatePage(load);
            let $specificTopic = getSpecificTopic();

            for (let index = 0; index < $specificTopic.length; index++) {
                let value = $specificTopic[index].value;
                if (value === true) {
                    $("#careerLevelDropdown-CF").removeAttr('data-dropdownmenuclosed');
                    $(".dropdown-arrow-CF").removeClass('arrow-dropdown-arrow-CF');
                    $(".topics-dropdown-CF").addClass("filtersDisabled");
                    $(".format-dropdown-CF").addClass("filtersDisabled");
                    $(".type-of-education-dropdown-CF").addClass("filtersDisabled");
                    $(".language-dropdown-CF").addClass("filtersDisabled");
                    $(".date-dropdown-CF").addClass("filtersDisabled");
                    $(this).hide();
                }
            }
        });

        $("#addedFilters-CF").on("click", function () {
            $("#courseFinderDropdowns-CF .filtersDisabled").removeClass("filtersDisabled");

            updatePage(false);
        });

        $searchField.on("keyup", function (e) {
            if (e.keyCode === 13) {
                updatePage(false);
            }

            addBubble();
        });


        function addBubble() {

            let $childrenNode = $("#search-field-CFautocomplete-list").children();

            $.each($childrenNode, function () {

                $(this).click(function (e) {
                    let value = $(this).find(".value").data("value");
                    filters.push(value);
                    deleteSingleFilter(value);
                    updatePage(false);
                });
            });
        }

        $sortDrownElements.on("click", function () {
            $sortDrownElements.removeClass("start-date-dropdown-selected");
            $(this).addClass("start-date-dropdown-selected");
            let $selectedElement = $(this).find('p');
            let $labelTitle = $selectedElement.text();
            $sortedTitle.text($labelTitle);
            $sortedTitle.data("value", $selectedElement.data("value"));
            updatePage(false);
        });


        $(".deleteAllFilters-CF").on("click", function () {

        });


        function preselectFilters() {

            const parameters = getQueryParameters(),
                $dropdownElements = $('.dropdown-topics-menu-CF li, .dropdown-menu-CF li');

            if (parameters) {
                $.each(parameters, function () {
                    $.each(this, function (parameterIndex, parameterValue) {
                        $.each($dropdownElements, function () {
                            let $elementValue = $(this).find("p").data("value").toString();
                            if (parameterValue === $elementValue) {
                                $(this).trigger('click', [true]);
                                return false;
                            }
                        });
                    });
                });
            }
        }

        function waitForElementToExist(selector, time) {
            if($(selector).length > 0) {
                preselectFilters();
                return;
            }
            else {
                setTimeout(function() {
                    waitForElementToExist(selector, time);
                }, time);
            }
        }

        waitForElementToExist('.dropdown-topics-menu-CF li, .dropdown-menu-CF li', 500);

        $(".speaker").slice(0, 6).show();
        $speakersSeeAllButton.on('click', function (e) {
            e.preventDefault();
            $(".speaker:hidden").slice(0, 6).slideDown();
            if ($(".speaker:hidden").length == 0) {
                $speakersSeeAllButton.hide();
            }
            $('html,body').animate({
                scrollTop: $(this).offset().bottom
            }, 800);
        });

        $("#first-dropdown li").each(function () {
            $(this).on("click", function () {
                let $length = $("#first-dropdown .clickedEl").length;
                let language = $("#language").data("language");
                if ($length !== 0) {
                    $(".first-dropdown-label").text("");
                    if ($length === 1) {
                        $(".first-dropdown-size").text($length + " " + "Level");
                    } else {
                        $(".first-dropdown-size").text($length + " " + "Levels");
                    }
                } else {
                    if (language === "en") {
                        $(".first-dropdown-size").text("some Level");
                    } else {
                        $(".first-dropdown-size").text("irgendein Level");
                    }
                }
            });
        });

        $("#second-dropdown li").each(function () {
            $(this).on("click", function () {
                let $length = $("#second-dropdown .clickedEl").length;
                let language = $("#language").data("language");
                if ($length !== 0) {
                    $(".second-dropdown-label").text("");
                    if ($length === 1){
                        if (language === "en") {
                            $(".second-dropdown-size").text($length + " " + "Topic");
                        } else {
                            $(".second-dropdown-size").text($length + " " + "Thema");
                        }

                    } else {
                        if (language === "en") {
                            $(".second-dropdown-size").text($length + " " + "Topics");
                        } else {
                            $(".second-dropdown-size").text($length + " " + "Themen");
                        }
                    }

                } else {
                    if (language === "en") {
                        $(".second-dropdown-size").text("all Topics");
                    } else {
                        $(".second-dropdown-size").text("allen Themen");
                    }
                }
            });
        });

        $("#third-dropdown li").each(function () {
            $(this).on("click", function () {
                let $length = $("#third-dropdown .clickedEl").length;
                let language = $("#language").data("language");
                if ($length !== 0) {
                    $(".third-dropdown-label").text("");
                    if ($length === 1){
                        if (language === "en") {
                            $(".third-dropdown-size").text($length + " " + "Format");
                        } else {
                            $(".third-dropdown-size").text($length + " " + "Format");
                        }

                    } else {
                        if (language === "en") {
                            $(".third-dropdown-size").text($length + " " + "Formats");
                        } else {
                            $(".third-dropdown-size").text($length + " " + "Formaten");
                        }
                    }

                } else {
                    if (language === "en") {
                        $(".third-dropdown-size").text("all Formats");
                    } else {
                        $(".third-dropdown-size").text("allen Formaten");
                    }
                }
            });
        });

        $('textarea').each(function(){
                $(this).val($(this).val().trim());
            }
        );

        function disableSpecificTopicForEnInCourseFinderPage() {
            let $dropdownElements = $('.dropdown-topics-menu-CF li, .dropdown-menu-CF li');
            let language = $("#language").data("language");
            $.each($dropdownElements, function () {
                let $elementValue = $(this).find("p").data("value").toString();
                if (language === "en" && $elementValue === "9134") {
                    $(this).addClass("displayNode");
                } else {
                    $(this).removeClass("displayNode");
                }
            });
        }

        function disableSpecificTopicForEnInHomePage() {
            let $dropdownElements = $('.homePageSliderSearch_dropdown li');
            let language = $("#language").data("language");
            $.each($dropdownElements, function () {
                let $elementValue = $(this).find("p").data("value").toString();
                if (language === "en" && $elementValue === "9134") {
                    $(this).addClass("displayNode");
                } else {
                    $(this).removeClass("displayNode");
                }
            });
        }

        disableSpecificTopicForEnInCourseFinderPage();
        disableSpecificTopicForEnInHomePage();

        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }

        // get filters in use elements and children to append on mobile keeping translation consistent
        let filtersInUse  =$("#filtersInUse-CF");
        let filtersInUseChildren = $("#filtersInUse-CF").children();
        // append html only on mobile
        function appendHtmlOnMobile() {
            if (isMobileDevice()) {
                $(".filter-in-user-mobile-container").append(filtersInUseChildren);
                $(".filter-in-user-mobile-container").append($("#addedFilters-CF"));
            } else {
                $("#courseFinderDropdowns-CF").append(filtersInUse);
                $("#courseFinderDropdowns-CF").append($("#addedFilters-CF"));
            }

            deleteAllFilter();
            showLessMore();
        }


        /////////////////////////////////
        // add click event to drop-downs
        // open/close drop-downs
        (function () {
            const dropdowns = [
                document.querySelector('#careerLevelDropdown-CF'),
                document.querySelector('#topicsDropdown-CF'),
                document.querySelector('#formatDropdown-CF'),
                document.querySelector('#typeOfEducationDropdown-CF'),
                document.querySelector('#languageDropdown-CF'),
                document.querySelector('#dateDropdown-CF'),
                document.querySelector('.start-date-filter-CF')
            ];

            // hide/close array of drop-downs
            function closeDropdownsFromArray(dropdownsArray) {

                dropdownsArray.forEach(function (dropdown) {
                    const parentEL = dropdown.parentNode;
                    const dropdownUL = parentEL.querySelector('ul');

                    if (dropdown.dataset.dropdownmenuclosed === 'true') {
                        const dropdownArrow = dropdown.querySelector('i');
                        const dropdownType = parentEL.querySelector('[data-dropdowntype]').dataset.dropdowntype;

                        dropdown.dataset.dropdownmenuclosed = 'false';
                        dropdownUL.style.display = 'none';
                        dropdownArrow.classList.remove('arrow-dropdown-arrow-CF');
                        dropdownUL.classList.remove("border-bottom-type-" + dropdownType);
                    }
                });

            }

            // attach click event
            dropdowns.forEach(function (element) {

                element.addEventListener('click', function (event) {

                    const parentEL = this.parentNode;
                    const dropdownUL = parentEL.querySelector('ul');
                    const dropdownArrow = this.querySelector('i');
                    const dropdownType = parentEL.querySelector('[data-dropdowntype]').dataset.dropdowntype;

                    // test the clicked drop-down is the open one
                    // if yes -> close only this drop-down
                    // if no -> close all drop-downs & open clicked one
                    if (this.dataset.dropdownmenuclosed === 'true') {
                        // dropdown is open, so close it
                        closeDropdownsFromArray([this]);
                    } else {
                        // close all drop-downs
                        closeDropdownsFromArray(dropdowns);

                        // open the clicked drop-down
                        this.dataset.dropdownmenuclosed = 'true';
                        dropdownArrow.classList.add('arrow-dropdown-arrow-CF');
                        dropdownUL.style.display = 'block';
                        dropdownUL.classList.remove("border-bottom-type-" + dropdownType);
                    }

                });

            });

        })();

        appendHtmlOnMobile();

        window.onresize = function () {
            appendHtmlOnMobile();
        };

    }

});

$(document).click(function (e) {
    if (!$("#courseFinderDropdowns-CF").is(e.target) && $("#courseFinderDropdowns-CF").has(e.target).length == 0)
    {
        $(".dropdownSelect").hide();
        $(".course-finder-dropdown-CF").attr("data-dropdownmenuclosed", "false");
        $(".dropdown-arrow-CF").removeClass("arrow-dropdown-arrow-CF");
    }

    // hide course finder autocomplete on click
    if (!$("#search-field-CFautocomplete-list").is(e.target) && $("#search-field-CFautocomplete-list").has(e.target).length == 0) {
        $("#search-field-CFautocomplete-list").hide();
    }
});